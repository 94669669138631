import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAppStore } from "@fortawesome/free-brands-svg-icons/faAppStore";
import { faPipe } from "@fortawesome/pro-light-svg-icons/faPipe";
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons/faGooglePlay";
import * as React from "react";
import {
  ChangeEvent,
  FormEvent,
  FormEventHandler,
  ReactNode,
  useState,
} from "react";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";

const encode = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

export default function Landing() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<ReactNode | null>(null);

  function onEmailChange(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "stayInformedRequest", userEmail: email }),
    })
      .then(() => {
        setLoading(false);
        setMessage(
          <div className="text-sm mb-4 text-green-500">
            Thanks for signing up! We'll let you know when the app launches!
          </div>
        );
        setEmail("");
      })
      .catch(() => {
        setLoading(false);
        setMessage(
          <div className="text-sm mb-4 text-red-500">
            Something went wrong! Please try again. If it happens again, email
            jeepduckit@gmail.com.
          </div>
        );
        setEmail("");
      });

    setLoading(true);
  }

  return (
    <main className="grow">
      <section className="xl:px-[25vw] md:py-32 bg-duck-blue text-white flex flex-row flex-wrap md:flex-nowrap items-center justify-center px-16 py-16">
        <div>
          <h2 className="text-2xl md:text-4xl font-black mb-4">
            The all new way to play I-spy your killer ride.
          </h2>
          <p className="text-sm">Your virtual community of Jeep Enthusiasts.</p>
          <div className="mt-16 flex items-center flex-col md:flex-row">
            <p className="flex-1 text-xl italic font-bold">
              Look for us in your favorite app store March 2024.
            </p>
            <div className="flex flex-row gap-2 items-center mx-4 my-4">
              <FontAwesomeIcon icon={faAppStore} className="text-6xl" />
              <FontAwesomeIcon icon={faPipe} className="text-8xl opacity-50" />
              <FontAwesomeIcon icon={faGooglePlay} className="text-6xl" />
            </div>
          </div>
        </div>
        <img
          className="w-full md:w-3/5 order-1"
          src="img/svg/duck-hero.svg"
          alt="DuckIt Trademark & Logo"
        />
      </section>
      <section className="xl:px-[25vw] flex flex-col gap-72 py-16 px-8 md:px-32 bg-gradient-to-br from-duck-yellow via-duck-yellow to-duck-bill">
        <div className="relative w-full sm:w-96 h-64 md:h-80 p-8 rounded-3xl bg-[#fce63f] drop-shadow-xl border-duck-darkYellow">
          <h2 className="absolute right-4 top-4 text-6xl font-black italic">
            1
          </h2>
          <h3 className="text-4xl mb-12 font-bold">Spot It</h3>
          <p className="text-lg md:text-2xl">
            You've spotted that one sick ride that deserves a good ducking.
          </p>
          <img
            className="absolute right-0 bottom-0 w-3/4 md:w-[200%] -mr-4 sm:-mr-8 md:-mr-36 -mb-36"
            src="img/steps-01-B.png"
            alt="jeep illustration"
          />
        </div>
        <div className="self-end relative p-8 sm:pl-28 w-full sm:w-80 md:w-96 h-80 sm:h-64 md:h-72 rounded-3xl bg-[#fce63f] drop-shadow-xl border-duck-darkYellow">
          <img
            className="absolute left-0 top-0 mt-24 sm:mt-8 md:-mt-8 -ml-6 sm:-ml-32 md:-ml-72 h-48 w-48 md:h-auto md:w-auto"
            src="img/steps-02.png"
            alt="QR code scan"
          />
          <h2 className="absolute right-4 top-4 text-6xl italic">2</h2>
          <h3 className="text-4xl mb-12 font-bold">Scan It</h3>
          <p className="text-lg md:text-2xl pl-36 sm:pl-0">
            If its got our sticker on it then you're ready to DuckIt!
          </p>
        </div>
        <div className="self-center relative w-72 md:w-96 h-80 p-8 rounded-3xl bg-[#fce63f] drop-shadow-xl border-duck-darkYellow">
          <img
            className="absolute w-32 top-0 left-0 -mt-8 -ml-24 md:-ml-28"
            src="img/steps-03-baseball.png"
            alt="Illustration of the male DuckIt duck dressed in a baseball uniform."
          />
          <img
            className="absolute w-32 left-4 top-0 -mt-36"
            src="img/steps-03-lifeguard.png"
            alt="Illustration of the male DuckIt duck dressed in a lifeguards outfit."
          />
          <img
            className="absolute w-32 right-0 bottom-0 -mb-16 -mr-12 sm:-mr-16"
            src="img/steps-03-ranger.png"
            alt="Illustration of the female DuckIt duck dressed in a rangers uniform."
          />
          <h2 className="absolute right-4 top-4 text-6xl font-black italic">
            3
          </h2>
          <h3 className="text-4xl mb-12">Duck It</h3>
          <p className="text-lg md:text-2xl">
            Use the DuckIt app to send your own custom duck or choose one from
            the extended DuckIt family.
          </p>
        </div>
        <div className="-mt-36 text-center self-center relative w-full m-8 p-8 rounded-3xl bg-[#fce63f] drop-shadow-xl border-duck-darkYellow">
          <h2 className="text-2xl mb-8">Stay In The Loop.</h2>
          <p className="mb-2">Find out early when we're ready to launch.</p>
          <form onSubmit={handleSubmit} className="mb-4">
            <input
              type="email"
              className="py-2 px-4 text-sm rounded mr-2"
              name="userEmail"
              title="Email"
              placeholder="Your Email Address"
              value={email}
              onChange={onEmailChange}
              disabled={loading}
            />
            <input
              type="submit"
              className="bg-duck-blue uppercase text-white py-2 px-4 rounded font-bold text-sm my-2"
              value="Submit"
              disabled={loading}
            />
            <input
              type="hidden"
              name="subscriptionStatus"
              title="Status"
              value="Subscribed"
            />
            {loading && (
              <FontAwesomeIcon icon={faSpinner} spin={true} className="ml-2" />
            )}
          </form>
          {message}
          <i className="italic text-sm">
            Your email address is only used to send you one or two updates on
            our progress with the upcoming launch.
          </i>
        </div>
      </section>
    </main>
  );
}
