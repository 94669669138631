import * as React from "react";

export default function Landing() {
  return (
    <main className="grow">
      <section className="xl:px-[25vw] flex flex-col gap-72 pt-16 pb-32 px-8 md:px-32 bg-gradient-to-br from-duck-yellow via-duck-yellow to-duck-bill">
        <div className="relative w-full sm:w-96 h-64 md:h-80 p-8 rounded-3xl bg-[#fce63f] drop-shadow-xl border-duck-darkYellow">
          <h2 className="absolute right-4 top-4 text-6xl font-black italic">
            1
          </h2>
          <h3 className="text-4xl mb-12 font-bold">Spot It</h3>
          <p className="text-lg md:text-2xl">
            You've spotted that one sick ride that deserves a good ducking.
          </p>
          <img
            className="absolute right-0 bottom-0 w-3/4 md:w-[200%] -mr-4 sm:-mr-8 md:-mr-36 -mb-36"
            src="img/steps-01-B.png"
            alt="jeep illustration"
          />
        </div>
        <div className="self-end relative p-8 sm:pl-28 w-full sm:w-80 md:w-96 h-80 sm:h-64 md:h-72 rounded-3xl bg-[#fce63f] drop-shadow-xl border-duck-darkYellow">
          <img
            className="absolute left-0 top-0 mt-24 sm:mt-8 md:-mt-8 -ml-6 sm:-ml-32 md:-ml-72 h-48 w-48 md:h-auto md:w-auto"
            src="img/steps-02.png"
            alt="QR code scan"
          />
          <h2 className="absolute right-4 top-4 text-6xl italic">2</h2>
          <h3 className="text-4xl mb-12 font-bold">Scan It</h3>
          <p className="text-lg md:text-2xl pl-36 sm:pl-0">
            If its got our sticker on it then you're ready to DuckIt!
          </p>
        </div>
        <div className="self-center relative w-72 md:w-96 h-80 p-8 rounded-3xl bg-[#fce63f] drop-shadow-xl border-duck-darkYellow">
          <img
            className="absolute w-24 top-0 left-0 -mt-8 -ml-16 md:-ml-28"
            src="img/steps-03-baseball.png"
            alt="Illustration of the male DuckIt duck dressed in a baseball uniform."
          />
          <img
            className="absolute w-32 left-4 top-0 -mt-36"
            src="img/steps-03-lifeguard.png"
            alt="Illustration of the male DuckIt duck dressed in a lifeguards outfit."
          />
          <img
            className="absolute w-32 right-0 bottom-0 -mb-16 -mr-12 sm:-mr-16"
            src="img/steps-03-ranger.png"
            alt="Illustration of the female DuckIt duck dressed in a rangers uniform."
          />
          <h2 className="absolute right-4 top-4 text-6xl font-black italic">
            3
          </h2>
          <h3 className="text-4xl mb-12">Duck It</h3>
          <p className="text-lg md:text-2xl">
            Use the DuckIt app to send your own custom duck or choose one from
            the extended DuckIt family.
          </p>
        </div>
      </section>
    </main>
  );
}
