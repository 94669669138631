import { Route, Routes } from "react-router-dom";
import User from "./pages/User";
import Landing from "./pages/Landing";
import Instructions from "./pages/Instructions";
import MagicLink from "./pages/MagicLink";

export default function Router() {
  return (
    <Routes>
      <Route path="/user/:userId" element={<User />} />
      <Route path="/link/*" element={<MagicLink />} />
      <Route path="/instructions" element={<Instructions />} />
      <Route path="*" element={<Landing />} />
    </Routes>
  );
}
