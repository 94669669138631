import React from "react";

export default function MagicLink() {
  return (
    <main className="grow bg-duck-yellow h-full flex flex-col text-center items-center justify-center px-8 lg:px-32">
      <span className="text-xl">
        It looks like you're trying to click a magic link from your email!
        Please install the Duck It app and tap this link on your phone
      </span>
    </main>
  );
}
